import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Process = makeShortcode("Process");
const Link = makeShortcode("Link");
const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-giving-spirit",
      "style": {
        "position": "relative"
      }
    }}>{`🎁 Giving Spirit`}<a parentName="h1" {...{
        "href": "#-giving-spirit",
        "aria-label": " giving spirit permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Video src="https://www.youtube-nocookie.com/embed/Kk2JVP-HKI8?start=240" mdxType="Video" />
    <blockquote>
      <p parentName="blockquote">{`"One of the tragedies of modern life occurs when people go into aboriginal communities where
there is a lively gift exchange and say, 'You know, logically, you could turn this into a
commercial economy. If you just sold these things to one another, it would have the same
effect.'' This destroys the community."`}</p>
    </blockquote>
    <p>{`Perhaps we can reverse the above by building tools which allow us to present the commercial
economy with the same dictum: "You know, logically, you could turn this into a gift economy.
If you program reciprocal loops and recursive incentives, it would have the same effect,"
thereby discovering again what was `}<a parentName="p" {...{
        "href": "/learn/module-0/conversation/#old-gifts-anew"
      }}>{`old community in new form`}</a>{`...`}</p>
    <h3 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h3" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`There are many types of transactional exchange humans can enter into, and nearly all of them
are required in a fully functional and healthy society. This talk has been selected because
Lewis Hyde provides a balanced perspective on the place and importance of gift exchange within
an economy, emphasizing the kinds of work and contexts in which it is most appropriate.`}</p>
    <p>{`The brief should help you understand that this is not an idealistic module: it
is `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern/"
      }}>{`again a question of trade-offs`}</a>{`. The argument is not
that we should abandon any kind of economic activity other than gift-giving; simply that gifts
are the opposite extreme from purely commercial transactions and that we need to think carefully
about where on the spectrum our work best fits.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: A healthy society requires what kind of economy: market or gift?`}</p>
        <p>{`Both!`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`I had been introduced to the anthropology of gift exchange as a kind of economy and it struck
me almost immediately that this was a language which could be used to talk about the situation
of creative artists.`}</p>
    </blockquote>
    <p>{`Hyde talks about his own biography and the notion that poetry, in particular, might have a different economy. This led him to a series of `}<a parentName="p" {...{
        "href": "/learn/module-2/better-questions"
      }}>{`questions`}</a>{`:`}</p>
    <Process mdxType="Process">
      <p>{`Why don't creative artists earn a living the way lawyers, doctors and computer scientists do?`}</p>
      <p>{`Why do many artists feel a barrier between their work and the commercial world?`}</p>
      <p>{`The anthropology suggests that there is an ethic, and therefore a set of obligations, which come with a gift. So, if you are a gifted person, born with talents that are clearly not just created by your own efforts, are there obligations that come with that, and things that you're supposed to do with your `}<a parentName="p" {...{
          "href": "https://youtu.be/65IoNtMNlUk"
        }}>{`gift`}</a>{`?`}</p>
      <p>{`Do communities have obligations to people who have been gifted in one way or another?`}</p>
    </Process>
    <h3 {...{
      "id": "connection-circulation-spirit",
      "style": {
        "position": "relative"
      }
    }}>{`Connection, Circulation, Spirit`}<a parentName="h3" {...{
        "href": "#connection-circulation-spirit",
        "aria-label": "connection circulation spirit permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Hyde set out to read the anthropology, and many fairy tales and folk stories, about gift
exchange. He came to three conclusions about gift-giving which differentiate it from market
exchange.`}</p>
    <Process mdxType="Process">
      <p>{`Gift giving `}<strong parentName="p">{`creates a connection`}</strong>{` between `}<a parentName="p" {...{
          "href": "/learn/module-7/giving/#receiving-gracefully"
        }}>{`giver and receiver`}</a>{`. Gifts induce gratitude, and a consequent sense of obligation towards relationship. This is the `}<strong parentName="p">{`cardinal difference`}</strong>{` between gift and market exchange: part of the `}<em parentName="p">{`great virtue of the marketplace is that there is no enduring connection`}</em>{`. Hyde mentions as an example his travel to the presentation which required a series of brief commercial transactions: he would have never reached his destination if he had to form relationships with all of the people involved. Gift exchange, by contrast, does not allow us to participate in this disconnected fashion. "This is a useful way to begin to think about the commerce of the creative spirit."`}</p>
      <p>{`Gift exchange traditionally has much `}<strong parentName="p">{`wider circulations`}</strong>{` than just that passed between
two individuals, especially when gifts are given anonymously. Hyde quotes an Indian story of
two women who decide to dispense with their alms-giving duties by only giving gifts to each
other and who are consequently reincarnated as two wells with poisonous water. Another example
is Thomas Hutchinson's phrase "Indian givers", arising from Puritan encounters with Native
Americans who had a `}<strong parentName="p">{`different sense of what property is.`}</strong>{` For these people, when you gave
someone something, it wasn't theirs to keep: they had to pass it on. This perplexed the
Puritans and so the phrase has come to mean someone who is insincere about gift-giving, but
it `}<em parentName="p">{`really means the opposite`}</em>{`: someone who understands the actual ethic of gift-giving,
which is that `}<strong parentName="p">{`gifts flow through you, they are not yours to own`}</strong>{`. You are their custodian.
In fact, one functional definition of community is "a place where gift exchange can operate".`}</p>
      <p>{`When property circulates as a gift, it `}<strong parentName="p">{`increases in value`}</strong>{`, or it bestows `}<strong parentName="p">{`liveliness`}</strong>{`.
"There is some sense of increase, or of `}<em parentName="p">{`something gaining life`}</em>{` as it circulates." Hyde talks
about the fairy tale trope of being given seemingly worthless objects while traveling through
the `}<Link to="https://youtu.be/GxVaxOco7kg?t=4307" mdxType="Link">{`Land of Faerie`}</Link>{`, only to
wake up the next morning and see that they are gold. "What seemed worthless in one community
and state, turns out to be valuable in another community and state." The folktales tell of two
responses: there are those who try to go back and get more, or figure out the price and cash
in; and there are those who pass the gift on. For the person who begins to count, to reckon
the value of the gift; it turns back into ashes. For the other person, life becomes more lively.
"There's a kind of trans-substantiation, or morality, implied in how you treat a gift."
Like `}<a parentName="p" {...{
          "href": "/learn/module-2/debt/#communal-credit"
        }}>{`Graeber`}</a>{`, Hyde tells a Maori story in which the bounty of the forest is assumed to be a gift from nature and you must give part of it back to the forest, which is called "feeding the spirit of the gift. If you feed the spirit, all will stay lively and in motion".`}</p>
    </Process>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Gift-giving creates a `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` between giver and receiver.`}</p>
        <p>{`Connection`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: It has a wider `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` than just two individuals.`}</p>
        <p>{`Circulation`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: As property circulates in the form of gifts, it increases in value. That is, gift-giving bestows `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`.`}</p>
        <p>{`Liveliness`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Despite the Puritans' confusion, what is the actual ethic of gift-giving?`}</p>
        <p>{`Gifts flow through you, they are not yours to own.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "poetic-gifts-to-posterity",
      "style": {
        "position": "relative"
      }
    }}>{`Poetic Gifts to Posterity`}<a parentName="h3" {...{
        "href": "#poetic-gifts-to-posterity",
        "aria-label": "poetic gifts to posterity permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`There are poets whose lives matter to me; the fact that there are poets who have
`}<strong parentName="p">{`given their life`}</strong>{` to poetry matters to me; the fact that their work entered my life is
important and I feel gratitude towards these people for giving their lives to the work.
Gratitude is a sign that, at some level, a gift has been given and received.`}</p>
    </blockquote>
    <p>{`Many poets talk in this way about their practice. Hyde cites what Eugenio Montale wrote in an essay about Dante:`}</p>
    <blockquote>
      <p parentName="blockquote">{`True poetry is always in the nature of a gift, in that it presupposes the dignity of its recipient.`}</p>
    </blockquote>
    <p>{`Ona  similar note, Czeslaw Milosz wrote an essay about the different kinds of love described in Greek, in
particular one type which is not often remembered: `}<strong parentName="p">{`storge`}</strong>{`. This word denotes a tender care
or affection uniting parents and children, and perhaps some teachers and their pupils. Milosz writes`}</p>
    <blockquote>
      <p parentName="blockquote">{`It is also not impossible that storge may be applied to the relationship between a poet and
a generation of readers to come. Beneath the ambition to perfect one's art, without hope of
being rewarded by contemporaries, lurks a magnanimity of gift-offering to posterity.`}</p>
    </blockquote>
    <p>{`The idea that your work is not confined to the present moment and that you're working for some
kind of larger, human world is wonderfully motivating. Hyde mentions a story about Bob Dylan
from `}<Link to="https://www.goodreads.com/book/show/7800891-common-as-air" mdxType="Link"><em parentName="p">{`Common As Air`}</em></Link>{`,
his book about creative cultural commons which we all co-own. When Dylan was 18, he was
introduced by a friend to Woody Guthrie, whom he quickly learnt to imitate perfectly. Dylan later remarked:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Listening to Guthrie made me feel more like myself than ever before."   `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`This is a perplexing statement, because `}<a parentName="p" {...{
          "href": "/learn/module-4/self-enquiry"
        }}>{`what is the self`}</a>{`
such that a young person would feel `}<em parentName="p">{`like himself`}</em>{` in the presence of somebody else's art?
It's as if that self is already present, but isn't brought to the surface until the art of
some previous generation precipitates it `}{`[...]`}{` There is a feeling that there is a
common `}<Link to="https://www.youtube.com/watch?v=Oohg3LZd898&t=1344s" mdxType="Link">{`Self`}</Link>{`,
that the hyper-individualism we live with today is not a full enough story to explain how an
artist becomes an artist and comes to practice with his or her gifts.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What is the fundamental sign that a gift has been given `}<strong parentName="p">{`and received`}</strong>{`?`}</p>
        <p>{`Gratitude.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Giving a gift presupposes the `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` of its recipient. `}</p>
        <p>{`dignity.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "anonymous-and-lively",
      "style": {
        "position": "relative"
      }
    }}>{`Anonymous and Lively`}<a parentName="h3" {...{
        "href": "#anonymous-and-lively",
        "aria-label": "anonymous and lively permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Hyde continues this theme with a story about Pablo Neruda, who - as a boy - was gifted a
unique toy sheep anonymously, through a hole in the fence. He responded with his most precious
possession: a pine cone. Years later, he would write these haunting words:`}</p>
    <blockquote>
      <p parentName="blockquote">{`To feel the affection that comes from those unknown to us, who are watching over our sleep
and our solitude, over our dangers and our weaknesses: that is something still greater and
more beautiful because it widens out the boundaries of our being and unites all living things.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`This exchange brought home to me, for the first time, a precious idea: that all humanity is
somehow together. It won't surprise you, then, that I have attempted to give something resiny,
earth-like and fragrant - something like a pine cone - in exchange for human brotherhood `}{`[...]`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`This small and mysterious exchange of gifts remained deep inside me, deep and indestructible,
giving my poetry light.`}</p>
    </blockquote>
    <p>{`This points to a particular category of gift-exchange: the anonymous one. Often we know who has
given us a gift, so we have a directed sense of gratitude. Anonymity `}<a parentName="p" {...{
        "href": "https://www.brainpickings.org/2019/07/07/the-spell-of-the-sensuous-david-abram/"
      }}>{`widens the field`}</a>{` within which gifts operate, because if there is no obligation of reciprocity, how do you best respond? `}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Gifts from beyond your sphere of influence can be given back beyond your sphere of influence.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Anonymous gifts must be given back to whom?`}</p>
        <p>{`The more-than-human world.`}</p>
      </Card>
    </Flash>
    <p>{`In almost all traditional gift-giving practices - as seen with the Maori practice of 'feeding
the spirit of the gift' - the ultimate sphere of gift-giving is the bounty of nature herself,
with regards to whom we all stand as recipients. In order to maintain and increase the value
of the gifts we receive, we must treat them as gifts and pass them on so that they remain
`}<em parentName="p">{`lively`}</em>{`. This is directly applicable to creative artists. Hyde quotes May Sarton:`}</p>
    <blockquote>
      <p parentName="blockquote">{`There is only one real deprivation, and that is not to be able to give one's gift to whom
one loves the most. The gift turned inward, unable to be given, becomes a heavy burden, even
sometimes a kind of poison. It is as though the flow of life were backed up.`}</p>
    </blockquote>
    <p>{`Hyde also quotes the famous book `}<em parentName="p"><Link to="https://www.goodreads.com/book/show/35476.Black_Elk_Speaks" mdxType="Link">{`Black Elk Speaks`}</Link></em>{`,
which begins with a dedication that illustrates how whatever wisdom Black Elk has `}<strong parentName="p">{`is not his`}</strong>{`,
and that - if you find it in his book - it is there to be given back to his ancestors.`}</p>
    <blockquote>
      <p parentName="blockquote">{`What is good in this book is given back to the six grandfathers.`}</p>
    </blockquote>
    <p>{`The dedicated way of living epitomised by Black Elk represents a whole people people who are "living the spirit of
the gift", not trying to capitalize upon it, or bring it into the ego and claim it for their
own. However, it is important to note that both are options and there is a negative side to
gift-giving which can uphold oppressive structures and solidify - for instance - patriarchal
power.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Feeding the spirit of the gift and giving it on ensures that it remains `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`.`}</p>
        <p>{`lively.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "tricky-trades",
      "style": {
        "position": "relative"
      }
    }}>{`Tricky Trades`}<a parentName="h3" {...{
        "href": "#tricky-trades",
        "aria-label": "tricky trades permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Hyde recalls `}<em parentName="p">{`The Homeric Hymn to Hermes`}</em>{`, the original trickster, in which a key moment
occurs when Hermes - having stolen Apollo's cattle, and upon being admonished by his mother - makes
the point that if Zeus won't give him his due as an Olympian, he will steal it. All gift
exchange communities are somewhat insular and, if you're excluded, your only practical option
is to steal what you need. The key question is thus: `}<em parentName="p">{`how does the outsider find a way in`}</em>{`?`}</p>
    <p>{`There is another ancient Greek term which can help us here: the `}<strong parentName="p">{`hermaion`}</strong>{`, or gift of Hermes.
It is the lucky find, the accident, the thing you stumble upon. It is the crossroads moment
where two paths come together and, if you have the wit, you can make a work of art out of the
accidental find. This is why cultivating serendipitous
, `}<Link to="https://www.brainpickings.org/2017/03/09/atom-and-archetype-pauli-jung/" mdxType="Link">{`synchronous spaces`}</Link>{` is so important: this allows more and more outsiders to join the community of circulating gift exchange without resorting to theft.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: By cultivating synchronous spaces with the `}<a parentName="p" {...{
            "href": "/learn/module-7/scale-ability"
          }}>{`great gift of our time`}</a>{`, we can find increasingly lucky ways to bring outsiders in and grow the `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` of circulating gift exchange.`}</p>
        <p>{`community.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "what-can-we-do-with-all-this",
      "style": {
        "position": "relative"
      }
    }}>{`What Can We Do With All This?`}<a parentName="h3" {...{
        "href": "#what-can-we-do-with-all-this",
        "aria-label": "what can we do with all this permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`Just to become conscious of gift exchange is useful. To know that there is a spirit of the
gift. To know there is a distinction between the 'connecting world' and the world that
differentiates between people, and that we can choose to honour this spirit or not. To know we
might have the kind of artistic practice which can initiate transformations in the soul; that
can give us a taste of mystery and wilderness; that can help us bear our suffering; that can
put us in touch with a kind of fertility which is not exhausted by using it up and that offers
us a sense of an inhabitable world in which we have solidarity with what we take to be the
source of our gifts.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`To be conscious of this makes a difference`}</strong>{`.`}</p>
    </blockquote>
    <p>{`I will go a step further than Hyde: to ask what can be done with a gift and the knowledge of
how it operates is to apply an alienating and utilitarian framework to something indigenous
and `}<a parentName="p" {...{
        "href": "/learn/module-4/consensus/#legibility"
      }}>{`illegible`}</a>{`. It reminds me of Muriel Rukeyser:`}</p>
    <blockquote>
      <p parentName="blockquote">{`But there is one kind of knowledge — infinitely precious, time-resistant more than monuments,
here to be passed between the generations in any way it may be: never to be used. And that is
poetry.`}</p>
    </blockquote>
    <p>{`or Steve Biko:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A visitor to `}{`[a Westerner's]`}{` house is always met with the question ‘What can I do for you?’. This
attitude to see people not as themselves but as agents for some particular function is foreign
to us `}{`[Africans]`}{`. We are not a suspicious race. We believe in the inherent goodness of man. We enjoy man
for himself. We regard our living together not as an unfortunate mishap warranting endless
competition among us but as a deliberate act of God to make us a community of brothers and
sisters jointly involved in the quest for composite answers to the varied problems of life.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: All Lewis Hyde is really asking us to do is to be what of gift exchange?`}</p>
        <p>{`Conscious.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "one-last-link",
      "style": {
        "position": "relative"
      }
    }}>{`One Last Link`}<a parentName="h3" {...{
        "href": "#one-last-link",
        "aria-label": "one last link permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Gift-giving extends deeply into both history and the human psyche. If you enjoyed this talk, you can find perhaps the ultimate exposition on this particular issue in The Diamond Sutra, otherwise known as the `}<em parentName="p">{`Paramparamita`}</em>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`And again, O Subhûti, a Bodhisattva should `}<a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=Pw2vxMRxF5o&t=4543s"
        }}>{`in such wise give his gift`}</a>{` for the benefit of all beings. And why? Because, O Subhûti, `}<a parentName="p" {...{
          "href": "/learn/module-1/meaning/#the-heart-of-it"
        }}>{`the idea`}</a>{` of a being is no-idea. And those who are thus spoken of by the Tathâgata as all beings are indeed no-beings.`}</p>
    </blockquote>
    <p>{`In this particular text, too, we find an extension of the complementary opposites we have been examining throughout our shared work. Each end of the spectrum, and the infinite range of probability between them, is an idea and is therefore also what Buddha once called “no-idea”. So, we give our unique gift, we return our life whole-heartedly, in order to benefit all with whom we share it, never for a moment forgetting that - as long as we have in our minds any trace of the idea “all beings” - we cannot in truth give anything.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“As `}<em parentName="p">{`Prajna`}</em>{` has been frequently the subject of discussion, I shall limit myself to the exposition of Dana, giving. It does not just mean giving in charity or otherwise something material in one’s possession as is usually understood when we talk of ‘giving’. It means anything going out of oneself, disseminating knowledge, helping people in difficulties of all kinds, creating arts, promoting industry and social welfare, sacrificing one’s life for a worthy cause and so on. But this, however noble, Buddhist philosophers would say, is not enough as long as a man harbors the idea of giving in one sense or another. The genuine giving consists in not cherishing any thought of anything going out of one’s hands and being received by anybody else; that is to say, `}<strong parentName="p">{`in the giving there must not be any thought of a giver or a receiver`}</strong>{`, and of an object going through this transaction. When the giving goes on thus in Emptiness, it is the deed of `}<em parentName="p">{`Dana`}</em>{`, the first Paramita, flowing directly out of `}<em parentName="p">{`Prajna`}</em>{`, the final Paramita.”
-- Daisetz Suzuki, `}<a parentName="p" {...{
          "href": "https://terebess.hu/zen/mesterek/Merton-birds-of-appetite.pdf"
        }}>{`Zen and the Birds of Appetite`}</a></p>
    </blockquote>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`The Diamond Cutter`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/ethereum.png",
            "alt": "The Diamond Cutter"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.youtube.com/watch?v=Y5fIVLwGYI8" mdxType="Button">
    Listen
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      